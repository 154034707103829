/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 65번째 로그에서는 이바닥늬우스 에디와 함께  타다 금지법, 토스 인터넷 은행 인가, 모빌리티 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://stdout.fm/"
  }, "개발자 팟캐스트 stdout.fm")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ja/"
  }, "(일본어) 44bits 일본어판")), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "이바닥 늬우스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ebadak.news/"
  }, "이바닥늬우스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/ebadaknews/"
  }, "이바닥늬우스 - Facebook")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/ebadaknews"
  }, "이바닥늬우스 (@ebadaknews) / Twitter")), "\n"), "\n", React.createElement(_components.h2, null, "타다 금지법"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.fnnews.com/news/201912262021095886"
  }, "'타다금지법' 해외 자금 유입도 막았다..6000억 투자 유치 무산 - 파이낸셜뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/2019122511851"
  }, "타다 플랜B 실탄 500억 투자받는다 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.donga.com/news/article/all/20191206/98684260/1"
  }, "‘타다 금지법’ 국회 첫관문 통과… 18개월뒤 못 타나")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.poolus.kr/"
  }, "사용자 수 1위 카풀 풀러스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.khan.co.kr/kh_news/khan_art_view.html?art_id=201907102115005"
  }, "택시 사납금 내년부터 폐지…사회적 대타협 ‘탄력’ - 경향신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/society/view/2019/12/1100387/"
  }, "강원도 택시 기본요금만 ", React.createElement(_components.code, null, "6900원"), "?…유튜버가 폭로한 ", React.createElement(_components.code, null, "바가지 택시"), " - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://waygobooking.com/"
  }, "Waygo(웨이고) | 놀라운 모빌리티 생활")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.grubhub.com/"
  }, "Grubhub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.doordash.com/"
  }, "DoorDash Food Delivery")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gojek.com/gofood/"
  }, "GoFood: Aplikasi Pesan Antar Makanan Online 24 Jam | Gojek")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.grab.com/sg/food/"
  }, "GrabFood Delivery Service – Order Food Online To Your Doorstep | Grab SG")), "\n"), "\n", React.createElement(_components.h2, null, "우아한형제들 인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2019/12/27/2019122701454.html"
  }, "소상공인연합회 “배민·딜리버리히어로 결합 반대… 수수료 오르면 불매” - Chosunbiz > 산업 > 기업")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2019/12/31/2019123102937.html"
  }, "영혼까지 판 게 아니라, 판을 키운거다 - Chosunbiz > 산업 > 기업")), "\n"), "\n", React.createElement(_components.h2, null, "토스 LG유플러스 PG사업부 인수, 인터넷 은행 예비인가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/2019060470111"
  }, "LG유플러스 국내 2위 전자결제 사업 판다 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2019/12/20/2019122002820.html"
  }, "토스, LG유플러스 PG사업부 인수 마무리… \"혁신 결제 서비스 만들 것\" - Chosunbiz > 금융")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.thebell.co.kr/free/index.asp"
  }, "더벨 - 국내 최고 자본시장(Capital Markets) 미디어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.toss.im/2019/12/16/newsroom/press-release/toss-internet-only-bank/"
  }, "토스뱅크 컨소시엄, 제3 인터넷전문은행 예비인가 획득")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nicepay.co.kr/home/main.do"
  }, "NICE PAY")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.edaily.co.kr/news/read?newsid=01177526619240736"
  }, "'캐시노트' 이용하면 \"자영업자 은행 대출, 쉬워져요\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.rakuten-bank.co.jp/"
  }, "(일본어) 라쿠텐 은행")), "\n"), "\n", React.createElement(_components.h2, null, "IT 기업과 시가총액 순위"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/63/"
  }, "stdout_063.log: 44bits 밋업 후기, 당근마켓 플레이 스토어 베스트앱 선정, 네이버 코스피 3위 w/ ecleya")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.naver.com/sise/sise_market_sum.nhn"
  }, "시가총액 순위: 네이버 금융")), "\n"), "\n", React.createElement(_components.h2, null, "라인, 야후 재팬과 합병"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yahoo.com/"
  }, "Yahoo")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yahoo.co.jp/"
  }, "Yahoo! JAPAN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/finance/article/2019061815121"
  }, "네이버 첫 적자 내나…시총 10위→14위 추락 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pay.line.me/portal/global/main"
  }, "LINE Pay")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://paypay.ne.jp/"
  }, "(일본어) PayPay - QR과 바코드로 결제하는 스마트폰 앱")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pay.rakuten.co.jp/"
  }, "(일본어) 라쿠텐페이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jreast.co.jp/suica/"
  }, "(일본어) Suica：JR동일본")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2019/07/08-17/"
  }, "일본 세븐일레븐, 간편결제 해킹으로 사용자 카드 약 6억원 도난당해 - Byline Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kotra.or.kr/user/globalAllBbs/kotranews/list/2/globalBbsDataAllView.do?dataIdx=163825&column=&search=&searchAreaCd=&searchNationCd=&searchTradeCd=&searchStartDate=&searchEndDate=&searchCategoryIdxs=&searchIndustryCateIdx=&page=1&row=100"
  }, "유망 - 일본 핀테크 시장동향 - 뉴스 - KOTRA 해외시장뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.kotra.or.kr/user/globalAllBbs/kotranews/album/2/globalBbsDataAllView.do?dataIdx=166170&searchNationCd=101003"
  }, "일본 젊은층들에게 핀테크는 貧테크다! - 뉴스 - KOTRA 해외시장뉴스")), "\n"), "\n", React.createElement(_components.h2, null, "모빌리티 분야 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.humaxdigital.com/"
  }, "휴맥스 - 비디오 & 브로드밴드 게이트웨이, 차량용 인포테인먼트 & 안테나, 카셰어링")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.humaxdigital.com/archives/3074"
  }, "휴맥스, 국내 주차장 운영 1위 업체 '하이파킹' 인수…1700억원에 지분 100% | HUMAX-Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kotra.or.kr/user/globalAllBbs/kotranews/album/2/globalBbsDataAllView.do?dataIdx=173987&searchNationCd=101011"
  }, "프랑스 전동식 킥보드산업 동향 - 뉴스 - KOTRA 해외시장뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20191012042000003"
  }, "전동킥보드 등 개인모빌리티 2년간 인명사고 289건…사망 8건 | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23040854"
  }, "전동킥보드에 치인 보행자 첫 사망…지난해 사고만 117건 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://japantaxi.co.jp/"
  }, "(일본어) JapanTaxi주식회사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.beinews.net/news/articleView.html?idxno=20820"
  }, "중국산업 - 디디추싱, 일본 택시시장 진출 - 비아이뉴스")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
